import React from 'react';
import { Row, Col } from 'react-bootstrap';
import './ApartmentDetails.css';
import { GiWashingMachine, GiHomeGarage, GiGiftOfKnowledge, GiWifiRouter, GiTv, GiShower, GiWhirlpoolShuriken, GiMountainCave, GiFlowerPot, GiWineBottle, GiWindow } from "react-icons/gi";
import { PiSwimmingPool } from "react-icons/pi";
import { TbAirConditioning } from "react-icons/tb";
import { BiDish } from "react-icons/bi";
import { RiParkingLine } from "react-icons/ri";
import { useTranslation } from 'react-i18next';

// Map of amenities to their corresponding icons and translation keys
const amenitiesIcons = {
  "Lavatrice": { icon: <GiWashingMachine />, translationKey: "washing_machine" },
  "Lavastoviglie": { icon: <BiDish />, translationKey: "dishwasher" },
  "Garage-privato": { icon: <GiHomeGarage />, translationKey: "private_garage" },
  "Welcome-kit": { icon: <GiGiftOfKnowledge />, translationKey: "welcome_kit" },
  "Wifi": { icon: <GiWifiRouter />, translationKey: "wifi" },
  "Smart-tv": { icon: <GiTv />, translationKey: "smart_tv" },
  "Posto-auto": { icon: <RiParkingLine />, translationKey: "parking_space" },
  "Aria-condizionata": { icon: <TbAirConditioning />, translationKey: "air_conditioning" },
  "Doccia-emozionale": { icon: <GiShower />, translationKey: "emotional_shower" },
  "Idromassaggio": { icon: <GiWhirlpoolShuriken />, translationKey: "whirlpool" },
  "Vista-magnifica": { icon: <GiMountainCave />, translationKey: "magnificent_view" },
  "Giardino": { icon: <GiFlowerPot />, translationKey: "garden" },
  "Piscina": { icon: <PiSwimmingPool />, translationKey: "swimming_pool" },
  "Cantina": { icon: <GiWineBottle />, translationKey: "cellar" },
  "Terrazzo": { icon: <GiWindow />, translationKey: "terrace" }
};

/**
 * ApartmentAmenities Component
 * 
 * This component is responsible for displaying the amenities available in a specific apartment. 
 * It maps each amenity to a corresponding icon and name, which are then displayed in a grid format.
 * 
 * Key Features:
 * 
 * 1. **Icon Mapping**:
 *    - Each amenity is mapped to a specific icon and translation key using the `amenitiesIcons` object.
 *    - The translation key is used to display the amenity name in the user's preferred language.
 * 
 * 2. **Responsive Grid Layout**:
 *    - The amenities are displayed in a responsive grid using Bootstrap's `Row` and `Col` components.
 *    - The grid adjusts to different screen sizes, showing more or fewer items per row depending on the device.
 */

export default function ApartmentAmenities({ amenities }) {
  const { t } = useTranslation(); // Hook for translations

  return (
    <div className="amenities-container">
      <Row>
        {amenities.map((amenity, index) => {
          const amenityData = amenitiesIcons[amenity]; // Retrieve icon and translation key for the amenity
          return (
            <Col xs={12} md={6} lg={3} key={index} className="amenity-item">
              <div className="amenity-icon">{amenityData.icon}</div> {/* Display the icon */}
              <div className="amenity-name">{t(amenityData.translationKey)}</div> {/* Display the translated name */}
            </Col>
          );
        })}
      </Row>
    </div>
  );
}