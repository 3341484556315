// © 2024 Fabio Carucci. All Rights Reserved.
import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet'; // Importa Helmet
import './ApartmentPage.css';
import CustomNavbar from '../../components/navbar/CustomNavbar';
import ContainerPhotos from '../../components/photos\' container/ContainerPhotos';
import { Container, Row, Col, Button, Spinner } from 'react-bootstrap';
import ApartmentDescription from '../../components/apartments details/ApartmentDescription';
import { DayPicker } from 'react-day-picker';
import 'react-day-picker/dist/style.css';
import MobileContainerPic from '../../components/photos\' container/MobileContainerPic';
import Footer from '../../components/footer/Footer';
import { useTranslation } from 'react-i18next';
import { enGB, it, fr, de, es } from 'date-fns/locale';
import { useCookies } from 'react-cookie';
import MapComponent from '../../components/map/MapComponent';
import ApartmentRecommendations from '../../components/apartments details/ApartmentRecommendations';
import { useApartments } from '../../context/ApartmentsContext';

/**
 * The ApartmentPage component displays detailed information about a specific apartment.
 * It includes a calendar for date selection, a map view, and recommendations for similar apartments.
 * 
 * Key features:
 * - Dynamic data loading based on apartment ID from URL parameters.
 * - Responsive design adjustments for calendar and image containers.
 * - Cookie consent management for loading images and maps.
 * - Calendar component for selecting date ranges with fixed positioning on scroll.
 * - Modal iframe for reservation form with dynamic URL based on selected dates.
 * 
 * The component utilizes React hooks for state management and effects, and React-Bootstrap for layout and styling.
 */

const ApartmentPage = () => {
  const [cookies] = useCookies(['cookieConsent']);
  const { t, i18n } = useTranslation();

  // Maps of languages of date-fns
  const locales = {
    "en-EN": enGB,
    "it-IT": it,
    "fr-FR": fr,
    "de-DE": de,
    "es-ES": es
  };

  const currentLocale = locales[i18n.language] || enGB;

  // Get the apartment ID from the URL parameters and initialize navigation and refs
  const { id } = useParams();
  const navbarRef = useRef(null);
  const calendarRef = useRef(null);

  // State management
  const [navbarHeight, setNavbarHeight] = useState(0);
  const [isRangeSelected, setIsRangeSelected] = useState(false);
  const [range, setRange] = useState({ from: undefined, to: undefined });
  const [cellSize, setCellSize] = useState('40px');
  const [isCalendarFixed, setIsCalendarFixed] = useState(false);
  const [originalCalendarOffsetTop, setOriginalCalendarOffsetTop] = useState(null);

  const [showIframe, setShowIframe] = useState(false);
  const [isIframeLoading, setIsIframeLoading] = useState(true);

  const { apartments } = useApartments();

  // Find the apartment data by ID from the JSON file
  const apartment = apartments.find(apartment => apartment.id === id);

  // Update navbar height on resize and on component load
  useEffect(() => {
    const updateNavbarHeight = () => {
      if (navbarRef.current) {
        setNavbarHeight(navbarRef.current.offsetHeight);
      }
    };

    window.addEventListener('resize', updateNavbarHeight);
    updateNavbarHeight();

    return () => {
      window.removeEventListener('resize', updateNavbarHeight);
    };
  }, [apartments]);

  // Update the calendar cell size based on the container width
  useEffect(() => {
    const updateCellSize = () => {
      if (calendarRef.current) {
        const colWidth = calendarRef.current.offsetWidth;
        const newCellSize = `calc(${Math.floor(colWidth / 7)}px - 2px)`;
        setCellSize(newCellSize);
      }
    };

    updateCellSize();
    window.addEventListener('resize', updateCellSize);

    return () => {
      window.removeEventListener('resize', updateCellSize);
    };
  }, [apartments]);

  // Handle date selection in the calendar
  const handleDateSelect = (selectedRange) => {
    if (range.from && range.to) {
        setRange({ from: undefined, to: undefined });
        setIsRangeSelected(false);
    } else if (range.from || range.to) {
        setRange(selectedRange);
        setIsRangeSelected(true); 
    } else {
        setRange(selectedRange);
        setIsRangeSelected(false);   
    }
  };

  // Format the selected date range for display
  const formatRange = (range) => {
    if (range.from && range.to) {
      const options = { month: 'short', day: 'numeric' };
      const fromDate = range.from.toLocaleDateString(undefined, options);
      const toDate = range.to.toLocaleDateString(undefined, options);
      return `${fromDate} > ${toDate}`;
    } else if (range.from) {
      const fromDate = range.from.toLocaleDateString(undefined, { month: 'short', day: 'numeric' });
      return `${fromDate} > ${t('select_checkout_dates')}`;
    } else {
      return t('select_checkin_dates');
    }
  };

  // Clear the selected date range
  const handleDateClear = () => {
    setRange({});
    setIsRangeSelected(false);
  };

  // Handle scroll to fix the calendar at the top of the page when scrolling
  useEffect(() => {
    if (window.innerWidth >= 992) {
      const handleScroll = () => {
        if (calendarRef.current) {
          const scrollY = window.scrollY;
          const navbarHeight = navbarRef.current.offsetHeight;

          // Save the original offsetTop the first time it's calculated
          if (originalCalendarOffsetTop === null) {
            setOriginalCalendarOffsetTop(calendarRef.current.offsetTop - navbarHeight);
          }

          // Check if the calendar should become fixed at the top
          if (scrollY >= originalCalendarOffsetTop - 10) {
            setIsCalendarFixed(true);
            document.documentElement.style.setProperty('--calendar-top-offset', `${navbarHeight + 10}px`);
          } 
          // Check if the calendar should return to its original position
          else if (scrollY < originalCalendarOffsetTop + 10) {
            setIsCalendarFixed(false);
          }
        }
      };

      handleScroll(); // Perform the initial check

      window.addEventListener('scroll', handleScroll);
      return () => window.removeEventListener('scroll', handleScroll);
    }
  }, [navbarHeight, originalCalendarOffsetTop]);

  // Display a "Not found" message if the apartment ID is not found in the data
  if (!apartment) {
    return <div className="apartment-notfound">Apartment not found</div>;
  }

  const handlePopupClick = () => {
    setShowIframe(true);
  }

  // Funzione per formattare le date in DD-MM-YYYY
  const formatDate = (date) => {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // I mesi sono indicizzati da 0
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  // Costruire l'URL dinamico con l'appartamento e le date (se disponibili)
  let iframeUrl = `https://api.dvc-consulting.it/widget/form/ZWpAJkPimKKC0svr4lmV?appartamento=${encodeURIComponent(apartment.name)}`;

  // Aggiungere le date se sono selezionate
  if (range.from) {
    const formattedFromDate = formatDate(range.from);
    iframeUrl += `&check_in=${formattedFromDate}`;
  }

  if (range.to) {
    const formattedToDate = formatDate(range.to);
    iframeUrl += `&check_out=${formattedToDate}`;
  }

  return (
    <>
      <Helmet>
        <title>{apartment.name} | DVC apartments</title>
      </Helmet>
      <div className="apartment-page">
        {/* Navbar, photo container, and apartment description */}
        <CustomNavbar ref={navbarRef} fixed={true} />
        <ContainerPhotos apartment={apartment} navbarHeight={navbarHeight} />

        {/* Load images only if user consented to cookies */}
        {cookies.cookieConsent && (
          <div className='d-lg-none'>
            <img src={apartment.images[0]} alt="Main of Apartment" className='w-100'/>
          </div>
        )}

        <Container fluid className="pt-lg-5">
          <Row>
            <Col xs={12} lg={9}>
              <ApartmentDescription apartment={apartment} />
            </Col>
            <Col xs={12} md={6} lg={3} className="p-0">
              <div 
                ref={calendarRef} 
                className={`calendar-container ${isCalendarFixed ? 'calendar-fixed' : ''}`}
              >
                <div className='mb-3 ps-4 calendar-label'>
                  {formatRange(range)}
                </div>
                <div className='vertical-scroll-calendar'>
                  <DayPicker
                    mode='range'
                    selected={range}
                    onSelect={handleDateSelect}  
                    numberOfMonths={12}
                    showOutsideDays
                    disabled={[{ before: new Date() }]}
                    disableNavigation
                    style={{
                      "--rdp-cell-size": cellSize
                    }}
                    locale={currentLocale}
                  />
                </div>
                {/* Clear and Reserve buttons */}
                <div className="button-container">
                  <Button 
                    className="clear-btn rounded-3" // w-100 to make the button full width within the column
                    onClick={handleDateClear}
                  >
                    {t('clear')}
                  </Button>
                  <Button 
                    className="reserve-btn rounded-3" // w-100 to make the button full width within the column
                    disabled={!isRangeSelected}
                    onClick={handlePopupClick}
                  >
                    {t('reserve')}
                  </Button>
                </div>
              </div>
            </Col>

            {cookies.cookieConsent && (
              <Col xs={12} md={6} lg={12} className='d-lg-none'>
                <MobileContainerPic apartment={apartment}/>
              </Col>
            )}

            {cookies.cookieConsent && (
              <Col xs={12} lg={9}>
                <div className='map-details-container'>
                  <MapComponent apartments={[apartment]}/>
                </div>
              </Col>
            )}

            <Col xs={12} lg={9}>
              <div className='recommendations-container'>
                <ApartmentRecommendations apartment={apartment}/>
              </div>
            </Col>
            
            <Col xs={12} lg={9}>
              <Footer/>
            </Col>
          </Row>
        </Container>
      </div>

      {showIframe && (
        <div className="iframe-container">
          <button className="close-button" onClick={() => setShowIframe(false)}>✖</button>
          {isIframeLoading && (
            <div className="d-flex justify-content-center align-items-center" style={{ height: '100%' }}>
              <Spinner animation="border" variant="dark" />
            </div>
          )}
          <iframe
            src={iframeUrl}
            style={{ width: '100%', height: '100%', border: 'none', borderRadius: '3px' }}
            id="popup-ZWpAJkPimKKC0svr4lmV" 
            data-layout="{'id':'POPUP'}"
            data-trigger-type="alwaysShow"
            data-trigger-value=""
            data-activation-type="alwaysActivated"
            data-activation-value=""
            data-deactivation-type="neverDeactivate"
            data-deactivation-value=""
            data-form-name="Pop Up Prenotazione APT"
            data-height="516"
            data-layout-iframe-id="popup-ZWpAJkPimKKC0svr4lmV"
            data-form-id="ZWpAJkPimKKC0svr4lmV"
            title="Pop Up Prenotazione APT"
            onLoad={() => setIsIframeLoading(false)}
          >
          </iframe>
        </div>
      )}
    </>
  );
};

export default ApartmentPage;