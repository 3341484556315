import React, { useContext } from 'react';
import './Apartments.css';
import { Row, Col } from 'react-bootstrap';
import ApartmentCard from './ApartmentCard';
import { FiltersContext } from '../../context/FiltersContext'; // Import FiltersContext for accessing global filter state
import MapComponent from '../map/MapComponent'; // Import MapComponent for map view
import { useTranslation } from 'react-i18next';
import { useApartments } from '../../context/ApartmentsContext';

/**
 * ApartmentsList Component
 * 
 * This component is responsible for displaying a list of apartments based on the selected filters and view option.
 * 
 * Key Features:
 * 
 * 1. **Filtering Apartments**:
 *    - The apartments are filtered based on several criteria:
 *      - `selectedStates`: The cities or regions selected by the user.
 *      - `adults` and `childrens`: The total number of guests.
 *      - `pets`: Whether the apartment allows pets.
 *      - `selectedOption`: Additional options selected by the user (e.g., "Pets-friendly").
 * 
 * 2. **Sorting Apartments**:
 *    - After filtering, the apartments are sorted alphabetically by name for consistent display.
 * 
 * 3. **Conditional Rendering**:
 *    - The component conditionally renders either a map view or a list view of the apartments based on the `isMapView` prop.
 */

export default function ApartmentsList({ selectedOption, isMapView }) {
    const { t } = useTranslation(); 

    // Access filter states from the FiltersContext
    const { selectedStates, adults, childrens, pets } = useContext(FiltersContext);
    const totalGuests = adults + childrens; // Calculate total number of guests

    const { apartments } = useApartments();

    // Filter apartments based on selected states, number of guests, and pet policy
    const filteredApartments = apartments.filter(apartment => {
        const isStateMatch = selectedStates.length === 0 || selectedStates.includes(apartment.city); // Match selected city/region
        const isGuestMatch = apartment.guests >= totalGuests; // Match total number of guests
        const isPetsMatch = pets === 0 || apartment.options.includes("Pets-friendly"); // Match pet-friendly policy
        const isOptionMatch = selectedOption === "All-location" || apartment.options.includes(selectedOption); // Match additional options
        return isStateMatch && isGuestMatch && isPetsMatch && isOptionMatch; // Return true if all conditions are met
    });

    // Sort the filtered apartments alphabetically by name
    const sortedApartments = filteredApartments.sort((a, b) => {
        return a.name.localeCompare(b.name);
    });

    // Render MapComponent if isMapView is true, otherwise render the list of apartments
    return (
        <>
            {isMapView ? (
                // Render map view if isMapView is true
                <MapComponent apartments={sortedApartments} />
            ) : (
                // Check if there are any apartments to display
                sortedApartments.length > 0 ? (
                    <Row className='apartments-list'>
                        {sortedApartments.map((apartment, index) => (
                            <Col key={index} xs={12} md={6} lg={4}>
                                <ApartmentCard 
                                    id={apartment.id}
                                    city={apartment.city} 
                                    name={apartment.name} 
                                    beds={apartment.beds} 
                                    bathrooms={apartment.bathrooms} 
                                    guests={apartment.guests} 
                                    images={apartment.images}
                                    price={apartment.price}
                                />
                            </Col>
                        ))}
                    </Row>
                ) : (
                    // Display message when no apartments are found
                    <div className='no-results'>
                        {t('no_results')} {/* Show message if there are no results */}
                    </div>
                )
            )}
        </>
    );
}