import React, { useState, useRef, useEffect } from 'react';
import "./ApartmentDetails.css";
import { IoBedSharp, IoPeople, IoLogoEuro } from "react-icons/io5";
import { FaShower } from "react-icons/fa6";
import { Container, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import ApartmentAmenities from './ApartmentAmenities';

/**
 * ApartmentDescription Component
 * 
 * This component is responsible for displaying detailed information about a specific apartment, 
 * including its amenities, number of beds, bathrooms, guest capacity, and user reviews. It also 
 * supports internationalization (i18n) for displaying text in the user's preferred language.
 * 
 * Key Features:
 * 
 * 1. **Dynamic Content Display**:
 *    - Displays key apartment details such as city, name, number of beds, bathrooms, and guest capacity.
 *    - Shows a description of the apartment, with the ability to expand or collapse the text on smaller screens.
 * 
 * 2. **Review Management**:
 *    - Displays user reviews, with the option to expand or collapse individual reviews if they exceed a certain length.
 *    - Automatically checks if a review needs expansion based on its content height.
 * 
 * 3. **Responsive Design**:
 *    - Adjusts layout and display elements based on screen size, providing a user-friendly experience across devices.
 */

export default function ApartmentDescription({ apartment }) {
  const { t, i18n } = useTranslation(); // Hook for translations
  const currentLang = i18n.language.split('-')[0] || 'en'; // Get the current language or default to English

  const [isExpanded, setIsExpanded] = useState(false); // State to control description expansion
  const [expandedReviews, setExpandedReviews] = useState({}); // State to control expansion of individual reviews
  const [reviewNeedsExpansion, setReviewNeedsExpansion] = useState({}); // State to track which reviews need expansion

  const titleRef = useRef(null); // Reference to the title element for smooth scrolling

  const reviews = apartment.reviews || []; // Use reviews from apartment data

  // Toggle the expansion of the apartment description
  const toggleExpansion = () => {
    setIsExpanded(prevState => {
      if (prevState) {
        titleRef.current.scrollIntoView({ behavior: 'smooth' }); // Scroll back to title when collapsing
      }
      return !prevState;
    });
  };

  // Toggle the expansion of an individual review
  const toggleReviewExpansion = (index) => {
    setExpandedReviews(prevState => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  // Check if reviews need expansion based on their content height
  useEffect(() => {
    reviews.forEach((_, index) => {
      const reviewElement = document.getElementById(`review-${index}`);
      if (reviewElement) {
        const isOverflowing = reviewElement.scrollHeight > reviewElement.clientHeight;
        setReviewNeedsExpansion(prevState => ({
          ...prevState,
          [index]: isOverflowing,
        }));
      }
    });
  }, []);

  // Determine the correct translation for beds, bathrooms, and guests
  const bedText = apartment.beds === 1 ? t('bed') : t('beds');
  const bathroomText = apartment.bathrooms === 1 ? t('bathroom') : t('bathrooms');
  const guestText = apartment.guests === 1 ? t('guest') : t('guests');

  return (
    <Container className='apartment-details'>
      <Row>
        <Col xs={12}>
          <div ref={titleRef} className="city">{apartment.city}</div> {/* Display the city name */}
          <div className="name">{apartment.name}</div> {/* Display the apartment name */}
        </Col>
      </Row>
      <Row className='info'>
        <Col xs={12} sm={3}>
          <div>
            <IoBedSharp className='icon'/>
            <span>{apartment.beds} {bedText}</span> {/* Display number of beds */}
          </div>
        </Col>
        <Col xs={12} sm={3}>
          <div>
            <FaShower className='icon'/>
            <span>{apartment.bathrooms} {bathroomText}</span> {/* Display number of bathrooms */}
          </div>
        </Col>
        <Col xs={12} sm={3}>
          <div>
            <IoPeople className='icon'/>
            <span>{apartment.guests} {guestText}</span> {/* Display guest capacity */}
          </div>
        </Col>
        <Col xs={12} sm={3} className='mt-3 mt-lg-0'>
          <div>
            <IoLogoEuro className='icon'/>
            <span>{`> ${apartment.price}/${t('night')}`}</span> {/* Display price information */}
          </div>
        </Col>
      </Row>

      {/* Display the apartment description with expand/collapse functionality */}
      <div className={`description ${isExpanded || window.innerWidth >= 992 ? 'expanded' : 'collapsed'}`}>
        {apartment.description[currentLang]}
      </div>
      {/* Show the toggle button only on smaller screens if the description is long */}
      <button 
        className={`toggle-button d-lg-none ${apartment.description[currentLang].length > 200 ? '' : 'd-none'}`} 
        onClick={toggleExpansion}
      >
        {isExpanded ? t('read_less') : t('read_more')} {/* Toggle between "Read more" and "Read less" */}
      </button>

      {/* Display user reviews with the ability to expand/collapse */}
      <div className='reviews-container'>
        <Row>
          {reviews.length > 0 ? (
            reviews.map((review, index) => {
              const isReviewExpanded = expandedReviews[index];
              const reviewTextClass = isReviewExpanded ? 'expanded' : 'collapsed';

              return (
                <Col xs={12} lg={6} key={index} className='mb-3 mb-lg-0'>
                  <div className='reviews'>
                    <div className="author">
                      {review.name} {/* Display review author */}
                    </div>
                    <div id={`review-${index}`} className={`review ${reviewTextClass}`}>
                      {review.text} {/* Display review text */}
                    </div>
                    {/* Show the toggle button if the review text needs expansion */}
                    {reviewNeedsExpansion[index] && (
                      <button 
                        className="toggle-button"
                        onClick={() => toggleReviewExpansion(index)}
                      >
                        {isReviewExpanded ? t('read_less') : t('read_more')}
                      </button>
                    )}
                  </div>
                </Col>
              );
            })
          ) : (
            <Col xs={12}>
              <div className="no-reviews">
                {t('no_reviews')} {/* Show message if there are no reviews */}
              </div>
            </Col>
          )}
        </Row>
      </div>

      {/* Display apartment amenities */}
      <ApartmentAmenities amenities={apartment.amenities}/>
    </Container>
  );
}