import React, { useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';
import './CookieBanner.css'; 
import PrivacyLogo from '../assets/Logo privacy.png';
import { useTranslation } from 'react-i18next';

const CookieBanner = () => {
  const { t } = useTranslation();
  const [cookies, setCookie] = useCookies(['cookieConsent']);
  const [showBanner, setShowBanner] = useState(false);
  const [showPolicy, setShowPolicy] = useState(false); 
  const [preferences, setPreferences] = useState({
    essential: true, // I cookie essenziali sono sempre abilitati per default
    performance: false,
    profiling: false,
  });

  useEffect(() => {
    if (cookies.cookieConsent) {
      setShowBanner(false);
    } else {
      const timer = setTimeout(() => {
        setShowBanner(true);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [cookies.cookieConsent]);

  // Aggiorna le preferenze quando si apre il banner
  useEffect(() => {
    if (showBanner && cookies.cookieConsent) {
      setPreferences(cookies.cookieConsent);
    }
  }, [showBanner, cookies.cookieConsent]);

  const handleAcceptAll = () => {
    setCookie('cookieConsent', JSON.stringify({
      essential: true,
      performance: true,
      profiling: true,
    }), { path: '/' });
    setShowBanner(false);
  };

  const handleAcceptEssentialOnly = () => {
    setCookie('cookieConsent', JSON.stringify({
      essential: true,
      performance: false,
      profiling: false,
    }), { path: '/' });
    setShowBanner(false);
  };

  const handleSavePreferences = () => {
    setCookie('cookieConsent', JSON.stringify(preferences), { path: '/' });
    setShowBanner(false);
  };

  const handleChange = (e) => {
    const { name, checked } = e.target;
    setPreferences(prev => ({
      ...prev,
      [name]: checked,
    }));
  };

  useEffect(() => {
    const observer = new MutationObserver((mutationsList) => {
      for (const mutation of mutationsList) {
        if (mutation.type === 'childList') {
          // Seleziona tutti gli elementi con la classe 'cookie_policy_btn'
          const cookiePolicyButtons = document.querySelectorAll('.cookie_policy_btn');
          const cookieSettingsButtons = document.querySelectorAll('.cookie_settings_btn');
  
          cookiePolicyButtons.forEach(button => {
            button.removeEventListener('click', handlePolicyClick); // Rimuove l'event listener per sicurezza
            button.addEventListener('click', handlePolicyClick);
          });

          cookieSettingsButtons.forEach(button => {
            button.removeEventListener('click', handleSettingsClick); // Rimuove l'event listener per sicurezza
            button.addEventListener('click', handleSettingsClick);
          });
        }
      }
    });
  
    const config = { childList: true, subtree: true };
    observer.observe(document.body, config);
  
    // Cleanup function per rimuovere l'osservatore e l'event listener
    return () => {
      observer.disconnect();
  
      const cookiePolicyButtons = document.querySelectorAll('.cookie_policy_btn');
      const cookieSettingsButtons = document.querySelectorAll('.cookie_settings_btn');
      
      cookiePolicyButtons.forEach(button => {
        button.removeEventListener('click', handlePolicyClick);
      });

      cookieSettingsButtons.forEach(button => {
        button.removeEventListener('click', handleSettingsClick);
      });
    };
  }, []);
  
  const handlePolicyClick = (e) => {
    e.preventDefault();
    setShowBanner(true);
    setShowPolicy(true);
  };
  
  const handleSettingsClick = (e) => {
    e.preventDefault();
    setShowBanner(true);
    setShowPolicy(false);
  };

  const handleHidePolicy = () => {
    setShowPolicy(false);
  };

  if (!showBanner) return null; // Non montare il componente se il banner non deve essere mostrato

  return (
    <Modal className='cookie-banner' show={showBanner} centered backdrop="static">
      <Modal.Header>
        <Modal.Title>
          <img src={PrivacyLogo} alt="Privacy logo dvc" />
          <div>{t('cookie_settings')}</div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {!showPolicy ? (
          <>
            <p dangerouslySetInnerHTML={{ __html: t('cookie_info') }} />
            <Form>
              <Form.Group>
                <Form.Check
                  type="checkbox"
                  name="essential"
                  label={t('cookie_essential')}
                  checked={preferences.essential}
                  onChange={handleChange}
                  disabled
                />
                <Form.Check
                  type="checkbox"
                  name="performance"
                  label={t('cookie_performance')}
                  checked={preferences.performance}
                  onChange={handleChange}
                />
                <Form.Check
                  type="checkbox"
                  name="profiling"
                  label={t('cookie_profiling')}
                  checked={preferences.profiling}
                  onChange={handleChange}
                />
              </Form.Group>
              <Button onClick={handleSavePreferences} className="mt-3 save-preferences-btn">{t('save_preferences')}</Button>
            </Form>
          </>
        ) : (
          <div className="policy-content">
            <h4>Informativa sui Cookie</h4>
            <p>
              Benvenuto su DVC Apartments! Questa informativa sui cookie ti fornisce dettagli su come utilizziamo i cookie e altre tecnologie simili sul nostro sito, in conformità con il Regolamento Generale sulla Protezione dei Dati (GDPR). È importante che tu comprenda come e perché raccogliamo informazioni tramite cookie e come puoi gestire le tue preferenze.
            </p>

            <h5>1. Cosa sono i cookie?</h5>
            <p>
              I cookie sono piccoli file di testo che vengono memorizzati sul tuo dispositivo (computer, tablet, smartphone) quando visiti un sito web. Questi file contengono informazioni che possono essere utilizzate per migliorare la tua esperienza di navigazione e per rendere più efficienti le funzionalità del sito. I cookie possono essere impostati dal nostro sito (cookie di prima parte) o da terze parti (cookie di terza parte).
            </p>

            <h5>2. Tipi di cookie che utilizziamo</h5>
            <ul>
              <li>
                <p>
                  <strong>Cookie Essenziali (Necessari):</strong> Questi cookie sono indispensabili per il corretto funzionamento del sito web. Consentono di navigare nel sito e utilizzare le sue funzionalità di base. Senza questi cookie, alcuni servizi essenziali non potrebbero essere forniti. Questi cookie non richiedono il tuo consenso.
                  <br />
                  Accettando i cookie essenziali, acconsenti anche all'utilizzo di servizi di terze parti come <a href="https://cloudinary.com/privacy" target="_blank" rel="noopener noreferrer">Cloudinary</a>, <a href="https://gohighlevel.com/privacy" target="_blank" rel="noopener noreferrer">goHighLevel</a>, e <a href="https://www.mapbox.com/privacy/" target="_blank" rel="noopener noreferrer">Mapbox</a>, i quali possono raccogliere dati tramite cookie per garantire il corretto funzionamento del sito. Puoi consultare le loro informative sulla privacy seguendo i link.
                </p>
              </li>
              <li><strong>Cookie di Performance:</strong> Questi cookie raccolgono informazioni su come gli utenti interagiscono con il sito, come le pagine visitate e gli errori ricevuti. I dati sono anonimi e servono solo a migliorare il sito. Il tuo consenso è richiesto per l'uso di questi cookie.</li>
              <li><strong>Cookie di Funzionalità:</strong> Questi cookie ricordano le tue scelte, come la lingua o la regione, e forniscono funzionalità avanzate. Il tuo consenso è necessario per questi cookie.</li>
              <li><strong>Cookie di Targeting o Pubblicitari:</strong> Usati per mostrarti annunci pubblicitari più rilevanti e misurare l'efficacia delle campagne pubblicitarie. Il tuo consenso è richiesto per questi cookie.</li>
              <li><strong>Cookie di Terze Parti:</strong> Alcuni contenuti esterni (es. video, mappe) possono impostare cookie per tracciare la tua interazione. Non abbiamo controllo su questi cookie. Consulta le informative di queste terze parti per dettagli. Il tuo consenso è necessario.</li>
            </ul>

            <h5>3. Come utilizziamo i cookie?</h5>
            <div>
              Utilizziamo i cookie per:
              <ul>
                <li>Migliorare la tua esperienza di navigazione e personalizzare i contenuti.</li>
                <li>Memorizzare le tue preferenze e impostazioni.</li>
                <li>Fornire contenuti e annunci pubblicitari personalizzati.</li>
                <li>Analizzare il traffico e l'uso del sito per migliorare la qualità.</li>
                <li>Consentire l'integrazione con social media e altre piattaforme esterne.</li>
              </ul>
            </div>

            <h5>4. Consenso all'utilizzo dei cookie</h5>
            <p>
              Richiediamo il tuo consenso esplicito per l'uso di cookie non essenziali. Al tuo primo accesso, ti chiediamo di accettare o rifiutare i cookie tramite il nostro banner. Puoi modificare le tue preferenze in qualsiasi momento accedendo alle <span className='cookie_settings_btn'>Impostazioni della privacy</span>.
            </p>

            <h5>5. Come gestire i cookie?</h5>
            <p>
              Puoi gestire le tue preferenze sui cookie in qualsiasi momento tramite le <span className='cookie_settings_btn'>Impostazioni della privacy</span>. Puoi anche configurare il tuo browser per bloccare o eliminare i cookie. Tieni presente che disabilitare i cookie essenziali potrebbe compromettere alcune funzionalità del sito.
            </p>
            <div>
              Ecco come gestire i cookie nei principali browser:
              <ul>
                <li><a href="https://support.google.com/chrome/answer/95647?hl=it" target="_blank" rel="noopener noreferrer">Google Chrome</a></li>
                <li><a href="https://support.mozilla.org/it/kb/Gestione%20dei%20cookie" target="_blank" rel="noopener noreferrer">Mozilla Firefox</a></li>
                <li><a href="https://support.apple.com/it-it/guide/safari/sfri11471/mac" target="_blank" rel="noopener noreferrer">Safari</a></li>
                <li><a href="https://support.microsoft.com/it-it/help/17442/windows-internet-explorer-delete-manage-cookies" target="_blank" rel="noopener noreferrer">Internet Explorer</a></li>
                <li><a href="https://support.microsoft.com/it-it/help/4027947/windows-delete-cookies" target="_blank" rel="noopener noreferrer">Microsoft Edge</a></li>
              </ul>
            </div>

            <h5>6. Modifiche alla presente informativa sui cookie</h5>
            <p>
              Potremmo aggiornare questa informativa periodicamente. Ti invitiamo a consultare regolarmente questa pagina per restare aggiornato su eventuali modifiche.
            </p>

            <h5>7. Contatti</h5>
            <p>
              Per domande riguardanti questa informativa sui cookie, contattaci all'indirizzo <a href="mailto:info@dvcapartments.com">info@dvcapartments.com</a>.
            </p>
          </div>
        )}
      </Modal.Body>
      {!showPolicy ? (
        <Modal.Footer>
          <Row className="w-100">
            <Col xs={12} md={6} className="d-flex justify-content-center mb-3 mb-md-0">
              <Button onClick={handleAcceptEssentialOnly} className="w-100 accept-essentials-btn">{t('accept_cookie_essential_only')}</Button>
            </Col>
            <Col xs={12} md={6} className="d-flex justify-content-center">
              <Button onClick={handleAcceptAll} className="w-100 accept-all-btn">{t('accept_all_cookie')}</Button>
            </Col>
          </Row>
        </Modal.Footer>
        ) : (
        <Modal.Footer className='justify-content-center'>
          <Button className='come-back-to-cookie-settings-btn' onClick={handleHidePolicy}>
            Torna alla scelta dei cookie
          </Button>
        </Modal.Footer>
        )
      }
    </Modal>
  );
};

export default CookieBanner;