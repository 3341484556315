import React, { useState, useEffect, useRef } from 'react';
import Map, { Marker, Popup, useMap } from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import './MapComponent.css';
import iconNormal from '../../assets/location-dot-solid-red.svg';
import Supercluster from 'supercluster';
import { IoBedSharp, IoPeople } from "react-icons/io5";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { FaShower } from "react-icons/fa6";
import { Carousel, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

/**
 * MapComponent
 * 
 * The MapComponent displays a map with apartment locations and clusters them using Supercluster.
 * It allows users to interact with the map to view detailed information about individual apartments.
 * 
 * Key Features:
 * 
 * 1. **Dynamic Map View**:
 *    - Adjusts the initial view based on the number of apartments and screen size.
 * 
 * 2. **Clustering**:
 *    - Uses Supercluster to cluster apartment markers when zoomed out.
 * 
 * 3. **Popup with Carousel**:
 *    - Displays a popup with a carousel of apartment images when a marker is clicked.
 * 
 * 4. **Localization**:
 *    - Supports multiple languages with the use of i18n for translations.
 * 
 * 5. **Responsive Behavior**:
 *    - Adjusts UI elements based on the screen size and number of apartments.
 * 
 * Props:
 * - `apartments`: Array of apartment objects with details including coordinates, images, and other metadata.
 */

const MapComponent = ({ apartments }) => {

  const { t } = useTranslation();

  // Helper function to add Cloudinary transformations to image URLs
  const addCloudinaryTransformation = (url) => {
    const cloudinaryUrl = 'https://res.cloudinary.com/duwp1uf1h/image/upload';
    const publicId = url.split(cloudinaryUrl + '/')[1];
    return `${cloudinaryUrl}/t_Orizzontale/${publicId}`;
  };

  // State to manage the map view settings
  const [viewState, setViewState] = useState(() => {
    if (apartments.length === 1) {
      return {
        longitude: apartments[0].coordinates[1],
        latitude: apartments[0].coordinates[0],
        zoom: 12
      };
    } else if (window.innerWidth <= 992) {
      return {
        longitude: 8.1140,
        latitude: 44.7756,
        zoom: 9.57
      };
    } else {
      return {
        longitude: 8.1054,
        latitude: 44.7886,
        zoom: 9.87
      };
    }
  });

  const [selectedApartment, setSelectedApartment] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [clusters, setClusters] = useState([]);
  const [loadingImage, setLoadingImage] = useState(true);

  // States for managing map touch and keyboard interactions
  const [mapTouchEnabled, setMapTouchEnabled] = useState(apartments.length !== 1);
  const [isKeyPressed, setIsKeyPressed] = useState(apartments.length !== 1);
  const [isHovering, setIsHovering] = useState(false);
  const [showHoverMessage, setShowHoverMessage] = useState(false);
  
  useEffect(() => {
    // Event listeners for keyboard interactions
    const handleKeyDown = (e) => {
      if (e.key === 'Control' || e.key === 'Meta') {
        setIsKeyPressed(true);
        setShowHoverMessage(false);
      }
    };

    const handleKeyUp = (e) => {
      if (e.key === 'Control' || e.key === 'Meta') {
        setIsKeyPressed(false);
        if (isHovering) {
          setShowHoverMessage(true);
        }
      }
    };

    // Only add event listeners if there is exactly one apartment
    if (apartments.length === 1) {
      document.addEventListener('keydown', handleKeyDown);
      document.addEventListener('keyup', handleKeyUp);
    }

    // Cleanup event listeners on component unmount or when apartments change
    return () => {
      if (apartments.length === 1) {
        document.removeEventListener('keydown', handleKeyDown);
        document.removeEventListener('keyup', handleKeyUp);
      }
    };
  }, [isHovering, apartments.length]);

  // Event handlers for mouse hover effects
  const handleMouseEnter = () => {
    setIsHovering(true);
    if (!isKeyPressed) {
      setShowHoverMessage(true);
    }
  };
  
  const handleMouseLeave = () => {
    setIsHovering(false);
    setShowHoverMessage(false);
  };

  // Reference to the map object
  const mapRef = useMap();

  // Initialize Supercluster for clustering
  const supercluster = useRef(
    new Supercluster({
      radius: 20,
      maxZoom: 13,
    })
  ).current;

  // Function to update clusters based on map bounds and zoom level
  const updateClusters = () => {
    if (mapRef.current) {
      const bounds = mapRef.current.getBounds().toArray().flat();
      const zoom = Math.floor(viewState.zoom);
      const points = apartments.map(apartment => ({
        type: 'Feature',
        properties: { cluster: false, apartmentId: apartment.id },
        geometry: {
          type: 'Point',
          coordinates: [apartment.coordinates[1], apartment.coordinates[0]],
        },
      }));
      supercluster.load(points);
      setClusters(supercluster.getClusters(bounds, zoom));
    }
  };

  useEffect(() => {
    // Update clusters whenever apartments or viewState change
    if (apartments.length === 1) {
      setClusters([
        {
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: [apartments[0].coordinates[1], apartments[0].coordinates[0]],
          },
          properties: { apartmentId: apartments[0].id },
        },
      ]);
    } else {
      updateClusters();
    }
  }, [apartments, viewState]);

  useEffect(() => {
    // Update clusters initially
    setTimeout(() => {
      updateClusters();
    }, 0);
  }, []);

  // Handle opening the apartment detail page when popup is clicked
  const handlePopupClick = () => {
    if (selectedApartment) {
      window.open(`/apartment/${selectedApartment.id}`, '_blank');
    }
  };

  useEffect(() => {
    // Load the first image of the selected apartment and set loading state
    if (selectedApartment) {
      setLoadingImage(true);     
      const img = new Image();
      const highResUrl = addCloudinaryTransformation(selectedApartment.images[0]);
      img.src = highResUrl;
      img.onload = () => {
        setLoadingImage(false);
      };
    }
  }, [selectedApartment]);

  // Function to handle aria-hidden attribute for popup close button
  const handleAriaHidden = () => {
    const closeButton = document.querySelector('.mapboxgl-popup-close-button');
    if (closeButton) {
      closeButton.removeAttribute('aria-hidden');
    }
  };

  useEffect(() => {
    // Handle aria-hidden attribute when an apartment is selected
    if (selectedApartment) {
      setTimeout(handleAriaHidden, 0);
    }
  }, [selectedApartment]);

  // Handle map movement to reset popup on small screens
  const handleMapMove = () => {
    if (window.innerWidth < 576 && selectedApartment) {
      setSelectedApartment(null);
      setShowPopup(false);
    }
  };

  // Prevent click event propagation on the carousel
  const handleCarouselClick = (e) => {
    e.stopPropagation();
  };
  
  return (
    <div 
      className="map-container"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      style={{ width: '100%', height: (apartments.length === 1 && window.innerWidth >= 576) ? '400px' : 'calc(100vh - 100px - 46px)'}}
    >
      {showHoverMessage && (window.innerWidth >= 576) && (
        <div className="hover-message">
          <div>
            {t('click_to_enable_map')}
          </div>
        </div>
      )}
      {(window.innerWidth <= 576) && (apartments.length === 1) && (
        <button 
          onClick={() => {
            setMapTouchEnabled(!mapTouchEnabled);
            setIsKeyPressed(!isKeyPressed);
            }
          } 
          style={{
            position: 'absolute',
            top: '10px',
            right: '10px',
            zIndex: 995,
            padding: '10px',
            backgroundColor: 'var(--first-background-color)',
            opacity: 0.7,
            color: 'var(--text-color)',
            border: 'none',
            borderRadius: '10px'
          }}
        >
          {mapTouchEnabled ? `${t('disable_map')}` : `${t('enable_map')}`}
        </button>
      )}
      <Map
        ref={mapRef}
        initialViewState={viewState}
        style={{ width: '100%', height: (apartments.length === 1 && window.innerWidth >= 576) ? '400px' : 'calc(100vh - 100px - 46px)'}}        mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
        mapStyle="mapbox://styles/mapbox/satellite-streets-v12"
        projection="globe"
        antialias={true}
        onMove={(e) => {
          setViewState(e.viewState);
          handleMapMove();
        }}
        dragPan={isKeyPressed}
        scrollZoom={isKeyPressed}
        doubleClickZoom={isKeyPressed} 
        dragRotate={isKeyPressed}
        touchZoomRotate={mapTouchEnabled}
      >
        {clusters.map(cluster => {
          const [longitude, latitude] = cluster.geometry.coordinates;
          const { cluster: isCluster, point_count: pointCount } = cluster.properties;

          if (isCluster) {
            return (
              <Marker
                key={`cluster-${cluster.id}`}
                latitude={latitude}
                longitude={longitude}
                onClick={() => {
                  const expansionZoom = Math.min(
                    supercluster.getClusterExpansionZoom(cluster.id),
                    20
                  );
                  mapRef.current.flyTo({
                    center: [longitude, latitude],
                    zoom: expansionZoom,
                    speed: 1
                  });
                }}
              >
                <div
                  className="cluster-marker"
                  style={{
                    width: `${20 + (pointCount / apartments.length) * 20}px`,
                    height: `${20 + (pointCount / apartments.length) * 20}px`,
                  }}
                >
                  {pointCount}
                </div>
              </Marker>
            );
          }

          return (
            <Marker
              key={cluster.properties.apartmentId}
              longitude={longitude}
              latitude={latitude}
              anchor="bottom"
              onClick={() => {
                if (apartments.length > 1) {
                  const apartment = apartments.find(a => a.id === cluster.properties.apartmentId);
                  setSelectedApartment(apartment);
                  setTimeout(() => {
                    setShowPopup(true);
                  }, 200);
                }
              }}
            >
              <div className="marker-icon">
                <img
                  src={iconNormal}
                  alt="Marker Icon"
                  style={{ cursor: 'pointer', width: '24px', height: '24px' }}
                />
              </div>
            </Marker>
          );
        })}

        {selectedApartment && showPopup && (
          <Popup
            longitude={selectedApartment.coordinates[1]}
            latitude={selectedApartment.coordinates[0]}
            onClose={() => {
              setShowPopup(false);
              setSelectedApartment(null);
            }}
            closeButton={true}
            closeOnClick={false}
          >
            <div>
              <div
                className="popup-carousel-container"
                onClick={handleCarouselClick} // Prevent click event propagation on the carousel container
              >
                {loadingImage ? (
                  <div className="loading-message">
                    <Spinner animation="border" />
                  </div>                
                ) : (
                  <Carousel
                    indicators={false}
                    prevIcon={<IoIosArrowBack />}
                    nextIcon={<IoIosArrowForward />}
                  >
                    {selectedApartment.images.slice(0, 10).map((imageUrl, index) => (
                      <Carousel.Item key={index}>
                        <img
                          src={addCloudinaryTransformation(imageUrl)}
                          alt={`Slide ${index + 1}`}
                          className="d-block w-100 popup-carousel-image"
                          onClick={handlePopupClick} // Navigate to apartment page on image click
                        />
                      </Carousel.Item>
                    ))}
                  </Carousel>
                )}
              </div>
              <div className="popup-content" onClick={handlePopupClick}>
                <p>{selectedApartment.name}</p>
                <div className='popup-apartment-info'>
                  <div>
                    <IoBedSharp className='popup-apartment-icon'/>
                    <span>{selectedApartment.beds}</span>
                  </div>
                  <div>
                    <FaShower className='popup-apartment-icon'/>
                    <span>{selectedApartment.bathrooms}</span>
                  </div>
                  <div>
                    <IoPeople className='popup-apartment-icon'/>
                    <span>{selectedApartment.guests}</span>
                  </div>
                </div>
              </div>
            </div>
          </Popup>
        )}
      </Map>

      {/* <div className="coordinates-box">
        <p>Latitudine: {viewState.latitude.toFixed(4)}</p>
        <p>Longitudine: {viewState.longitude.toFixed(4)}</p>
        <p>Zoom: {viewState.zoom.toFixed(2)}</p>
      </div> */}
    </div>
  );
};

export default MapComponent;