// © 2024 Fabio Carucci. All Rights Reserved.
import React, { useEffect } from 'react';
import './App.css';
import './i18n/i18n';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Homepage from './views/homepage/Homepage';
import MaintenancePage from './views/maintenance page/MaintenancePage';
import ApartmentPage from './views/apartments page/ApartmentPage';
import { useLiveChat } from './context/LiveChatContext';
import CookieBanner from './cookies/CookieBanner';
import { useCookies } from 'react-cookie';

/**
 * Load an external script if it is not already present in the document.
 * 
 * @param {string} src - The source URL of the script to be loaded.
 * @param {string} id - The ID to uniquely identify the script element.
 */
const loadScript = (src, id) => {
  if (!document.getElementById(id)) {
    const script = document.createElement('script');
    script.id = id;
    script.src = src;
    script.async = true;
    document.body.appendChild(script);
    console.log(`Script ${id} loaded.`);
  }
};

/**
 * Remove an external script from the document by its ID if it exists.
 * 
 * @param {string} id - The ID of the script to be removed.
 */
const removeScript = (id) => {
  const script = document.getElementById(id);
  if (script) {
    script.remove();
    console.log(`Script ${id} removed.`);
  } else {
    console.log(`Script ${id} does not exist, nothing to remove.`);
  }
};

/**
 * Load the chat widget script if it is not already present.
 */
const loadChatScript = () => {
  if (!document.getElementById('chat-script')) {
    const script = document.createElement('script');
    script.id = 'chat-script';
    script.src = 'https://widgets.leadconnectorhq.com/loader.js';
    script.setAttribute('data-widget-id', '669a6c2bf8a8fb4f1168cf20');
    script.defer = true;
    document.body.appendChild(script);
  }
};

/**
 * Initialize third-party scripts based on user consent for cookies.
 * 
 * @param {object} consent - The user's consent preferences.
 */
const initializeThirdPartyScripts = (consent) => {
  // Handling performance scripts
  if (consent.performance) {
    console.log('Performance cookies accepted. Initializing Google Analytics...');

    // Uncomment to load Google Analytics script
    // loadScript('https://www.googletagmanager.com/gtag/js?id=UA-XXXXX-Y', 'google-analytics');
    // window.dataLayer = window.dataLayer || [];
    // function gtag(){window.dataLayer.push(arguments);}
    // gtag('js', new Date());
    // gtag('config', 'UA-XXXXX-Y');

    console.log('Performance cookies accepted. Initializing Google Tag Manager...');

    // Uncomment to load Google Tag Manager script
    // loadScript('https://www.googletagmanager.com/gtm.js?id=GTM-XXXXX', 'google-tag-manager');
    // window.dataLayer = window.dataLayer || [];
    // function gtag(){window.dataLayer.push(arguments);}
    // gtag('js', new Date());
    // gtag('config', 'GTM-XXXXX');

  } else {
    removeScript('google-analytics');
    removeScript('google-tag-manager');
  }

  // Handling profiling scripts
  if (consent.profiling) {
    console.log('Profiling cookies accepted. Initializing Google Ads...');

    // Uncomment to load Google Ads script
    // loadScript('https://www.googletagmanager.com/gtag/js?id=AW-XXXXX', 'google-ads');
    // window.dataLayer = window.dataLayer || [];
    // function gtag(){window.dataLayer.push(arguments);}
    // gtag('js', new Date());
    // gtag('config', 'AW-XXXXX');

    console.log('Profiling cookies accepted. Initializing Facebook Pixel...');

    // Uncomment to load Facebook Pixel script
    // loadScript('https://connect.facebook.net/en_US/fbevents.js', 'facebook-pixel');
    // window.fbq = window.fbq || function(){(window.fbq.q = window.fbq.q || []).push(arguments)};
    // window.fbq('init', 'YOUR_PIXEL_ID');
    // window.fbq('track', 'PageView');  
  } else {
    removeScript('google-ads');
    removeScript('facebook-pixel');
  }
};

function App() {
  const { showLiveChat } = useLiveChat();
  const [cookies] = useCookies(['cookieConsent']);

  // Check if the application is in maintenance mode
  const isMaintenanceMode = process.env.REACT_APP_MAINTENANCE_MODE === 'true';

  useEffect(() => {
    // Initialize third-party scripts if cookie consent is available
    if (cookies.cookieConsent) {
      const consent = cookies.cookieConsent;
      initializeThirdPartyScripts(consent);
      loadChatScript(); // Load the chat widget script
    }
  }, [cookies.cookieConsent]);

  /**
   * Update the visibility of the chat widget based on the provided visibility flag.
   * 
   * @param {boolean} isVisible - Whether the chat widget should be visible or not.
   */
  const updateChatVisibility = (isVisible) => {
    // Function to find and handle the chat widget element
    const handleChatWidget = () => {
      const chatWidgetHost = document.querySelector('chat-widget[widget-id="669a6c2bf8a8fb4f1168cf20"]');
      if (chatWidgetHost && chatWidgetHost.shadowRoot) {
        const chatWidget = chatWidgetHost.shadowRoot.querySelector('#lc_text-widget');
        if (chatWidget) {
          chatWidget.style.display = isVisible ? 'block' : 'none';
          observer.disconnect(); // Disconnect observer once element is found
        }
      }
    };

    // Use MutationObserver to watch for changes in the DOM
    const observer = new MutationObserver(() => handleChatWidget());

    // Start observing the document body for DOM changes
    observer.observe(document.body, { childList: true, subtree: true });

    // Perform an initial check
    handleChatWidget();
  };

  useEffect(() => {
    // Update the chat widget visibility based on the current state and cookie consent
    updateChatVisibility(showLiveChat && cookies.cookieConsent);
  }, [showLiveChat, cookies.cookieConsent]);

  return (
    <div>
      {isMaintenanceMode ? (
        // Render the MaintenancePage component if in maintenance mode
        <MaintenancePage />
      ) : (
        <>
          <Router>
            <Routes>
              <Route path="/" element={<Homepage />} />
              <Route path="/apartment/:id" element={<ApartmentPage />} />
            </Routes>
          </Router>
          <CookieBanner />
        </>
      )}
    </div>
  );
}

export default App;