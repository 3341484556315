import React from 'react';
import './ApartmentDetails.css';
import { Row, Col } from 'react-bootstrap';
import ApartmentCard from '../apartments list/ApartmentCard';
import { useTranslation } from 'react-i18next';
import { useApartments } from '../../context/ApartmentsContext';

// Function to calculate the distance between two coordinates using the haversine formula
const haversineDistance = (coords1, coords2) => {
    const toRad = (value) => (value * Math.PI) / 180; // Convert degrees to radians
    const R = 6371; // Earth's radius in km
    const [lat1, lon1] = coords1;
    const [lat2, lon2] = coords2;
    const dLat = toRad(lat2 - lat1); // Difference in latitude
    const dLon = toRad(lon2 - lon1); // Difference in longitude
    const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(toRad(lat1)) * Math.cos(toRad(lat2)) *
        Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return R * c; // Distance in km
};

/**
 * ApartmentRecommendations Component
 * 
 * This component is responsible for displaying a list of recommended apartments
 * based on the current apartment's details. It filters other apartments from a 
 * predefined list that can accommodate the same or a greater number of guests and 
 * calculates their distance from the current apartment using the haversine formula.
 * 
 * Key functionalities:
 * 
 * 1. **Distance Calculation**: 
 *    - Uses the haversine formula to calculate the distance in kilometers between 
 *      the coordinates of the current apartment and each other apartment.
 * 
 * 2. **Filtering and Sorting**:
 *    - Filters out the current apartment from the list.
 *    - Only includes apartments that can host the same or a greater number of guests.
 *    - Sorts the filtered apartments by proximity (closest first).
 *    - Limits the results to the top 3 closest apartments.
 * 
 * 3. **Rendering**:
 *    - If there are recommendations, it displays them in a responsive grid of cards.
 *    - If no recommendations are found (i.e., no apartments meet the criteria), 
 *      it displays a message indicating that no suitable apartments are available.
 * 
 * The component also supports internationalization (i18n) using the `useTranslation` 
 * hook from the `react-i18next` library, ensuring that the text content is displayed 
 * in the user's preferred language.
 */

export default function ApartmentRecommendations({ apartment }) {
    const { t } = useTranslation(); // Hook for translations

    const { apartments } = useApartments();

    // Filter and sort apartments based on distance and guest capacity
    const recommendations = apartments
        .filter(a => a.id !== apartment.id && a.guests >= apartment.guests) // Filter out the current apartment and ensure it can accommodate the same or more guests
        .map(a => ({
            ...a,
            distance: haversineDistance(apartment.coordinates, a.coordinates) // Calculate distance from the current apartment
        }))
        .sort((a, b) => a.distance - b.distance) // Sort by closest distance
        .slice(0, 3); // Limit to top 3 recommendations

    return (
        <div className='recommendations'>
            <div className='title'>{t('dvc_also_recommend')}</div> {/* Title for the recommendations section */}
            {recommendations.length > 0 ? ( // Check if there are any recommendations
                <Row className='cards-container'>
                    {recommendations.map((recommendation) => (
                        <Col key={recommendation._id} xs={12} md={4} className='cards'>
                            <ApartmentCard
                                id={recommendation.id}
                                city={recommendation.city}
                                name={recommendation.name}
                                beds={recommendation.beds}
                                bathrooms={recommendation.bathrooms}
                                guests={recommendation.guests}
                                images={recommendation.images}
                                price={recommendation.price}
                            />
                        </Col>
                    ))}
                </Row>
            ) : (
                <div className='no-recommendations'>
                    {t('no_recommendations_available')} {/* Display message if no recommendations are available */}
                </div>
            )}
        </div>
    )
}