import './CustomNavbar.css';
import logo from '../../assets/DVC Apartments.png';
import React, { useState, useEffect, forwardRef } from 'react';
import { Navbar, Nav, Dropdown, ButtonGroup, Container } from 'react-bootstrap';
import { FaUsers, FaRegCommentDots, FaGift, FaCompass, FaPhone } from "react-icons/fa";
import MainBar from '../mainbar/MainBar';
import ShareModal from '../share modal/ShareModal'; 
import ContactModal from '../contact us/ContactModal';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import AboutUsModal from '../aboutUs modal/AboutUsModal';

/**
 * CustomNavbar
 * 
 * This component renders a responsive navigation bar with different behaviors based on the page and screen size.
 * It includes a main navigation bar with dropdown functionality and a bottom navigation bar for mobile views.
 * Additionally, it integrates modals for sharing and contacting.
 * 
 * Props:
 * - `fixed` (boolean): If true, the navbar will be fixed at the top of the page.
 * - `isMapView` (boolean): If true, the navbar will have specific styles for map view.
 * - `ref` (React.Ref): Forwarded ref for the navbar component.
 */

const CustomNavbar = forwardRef(({ fixed, isMapView }, ref) => {
  const { t } = useTranslation();
  
  // State hooks for managing dropdown, modals, and scroll behavior
  const [isOpen, setIsOpen] = useState(false); // Toggle state for dropdown menu
  const [showModal, setShowModal] = useState(false); // State for sharing modal
  const [showContactModal, setShowContactModal] = useState(false); // State for contact modal
  const [showAboutUsModal, setShowAboutUsModal] = useState(false) // State for about DVC modal
  const [isScrolled, setIsScrolled] = useState(false); // State to track scroll position

  const navigate = useNavigate();
  const location = useLocation();
  
  // Determine if the current page is the home or apartment page
  const isHomePage = location.pathname === '/';
  const isApartmentPage = /^\/apartment\/[A-Za-z0-9_-]+$/.test(location.pathname);

  // Toggle function for the dropdown menu
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  // Scroll to the main bar or navigate to the home page based on the current page
  const scrollToMainBar = () => {
    if (isApartmentPage) {
      navigate('/'); // Navigate to home page
    } else {
      const mainbar = document.getElementById('main-bar');
      if (mainbar) {
        mainbar.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }
  };

  // Effect to scroll to the top of the page when on the home page
  useEffect(() => {
    if (location.pathname === '/') {
      window.scrollTo(0, 0);
    }
  }, [location.pathname]);

  // URL for sharing, can be modified based on the actual share URL
  const shareUrl = window.location.href;

  // Effect to handle scroll events for changing navbar appearance based on scroll position
  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY >= 50);
    };

    if (isApartmentPage) {
      window.addEventListener('scroll', handleScroll);
    }

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isApartmentPage]);

  // Determine the CSS class for the navbar based on props and scroll position
  const navbarClass = () => {
    if (fixed && !isApartmentPage) {
      return 'fixed';
    } else if (fixed && isApartmentPage && isScrolled) {
      return 'fixed';
    } else {
      return '';
    }
  };

  // Determine if the navbar should be hidden based on page and screen width
  const shouldHideNavbar = isApartmentPage && window.innerWidth < 992;

  return (
    <>
      {!shouldHideNavbar && (
        <Navbar
          bg="none"
          variant="dark"
          expand="lg"
          fixed={fixed ? "top" : null} // Apply fixed position if 'fixed' prop is true
          className={`custom-navbar ${navbarClass()}`}
          ref={ref}
        >
          <Container>
            {(fixed || isMapView) && (
              <Navbar.Brand href="/">
                <img
                  src={logo}
                  height="70"
                  className="d-none d-lg-inline-block align-top" // Hide on smaller screens
                  alt="Wander logo"
                />
              </Navbar.Brand>
            )}
            <Nav>
              {isHomePage && <MainBar />} {/* Conditionally render MainBar on home page */}
              {(fixed || isMapView) && (
                <Dropdown
                  className='ms-auto d-none d-lg-block' // Hide on smaller screens
                  as={ButtonGroup}
                  align="end"
                  onToggle={toggleDropdown}
                  show={isOpen} // Show dropdown if isOpen is true
                >
                  <Dropdown.Toggle
                    id="dropdown-custom-components"
                    className={`hamburger-toggle ${isOpen ? 'open' : ''}`} // Apply 'open' class if dropdown is open
                    onClick={toggleDropdown}
                  >
                    <div className="hamburger-icon"></div>
                  </Dropdown.Toggle>
                  <Dropdown.Menu className='p-3 hamburger' style={{ borderRadius: "10px" }}>
                    <Dropdown.Item 
                      eventKey="1" 
                      className='d-flex align-items-center py-2'
                      onClick={() => setShowContactModal(true)} // Show contact modal on click
                    >
                      <FaRegCommentDots style={{ marginRight: '15px', fontSize: "15pt" }} />
                      <span>{t('contact_us')}</span>
                    </Dropdown.Item>
                    <Dropdown.Item 
                      eventKey="2" 
                      className='d-flex align-items-center py-2'
                      onClick={() => setShowAboutUsModal(true)} // Show about DVC modal on click
                    >
                      <FaUsers style={{ marginRight: '15px', fontSize: "15pt" }} />
                      <span style={{marginRight: "60px"}}>{t('about_dvc_title')}</span>
                    </Dropdown.Item>
                    <Dropdown.Item 
                      eventKey="3" 
                      className='d-flex align-items-center py-2'
                      onClick={() => setShowModal(true)} // Show share modal on click
                    >
                      <FaGift style={{ marginRight: '15px', fontSize: "15pt" }} />
                      <span>{t('refer_a_friend')}</span>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              )}
            </Nav>
          </Container>
        </Navbar>
      )}

      {/* Bottom navigation bar for mobile view */}
      {!isMapView && (
        <div className="bottom-navbar d-lg-none">
          <Nav style={{width: isApartmentPage ? "100%" : "75%"}}>
            <Nav.Link onClick={scrollToMainBar} className="nav-item text-center">
              <FaCompass style={{ fontSize: "20pt" }} />
              <div style={{ fontSize: "8pt", marginTop: "5px" }}>{t('explore')}</div>
            </Nav.Link>
            <Nav.Link onClick={() => setShowContactModal(true)} className="nav-item text-center">
              <FaPhone style={{ fontSize: "20pt" }} />
              <div style={{ fontSize: "8pt", marginTop: "5px" }}>{t('contact_us')}</div>
            </Nav.Link>
            <Nav.Link onClick={() => setShowModal(true)} className="nav-item text-center">
              <FaGift style={{ fontSize: "20pt" }} />
              <div style={{ fontSize: "8pt", marginTop: "5px" }}>
                <span>{t('refer_a_friend').split(' ')[0]}</span>
                <br />
                <span>{t('refer_a_friend').split(' ').slice(1).join(' ')}</span>
              </div>            
            </Nav.Link>
          </Nav>
        </div>
      )}

      {/* Include the modals component here */}
      <ShareModal show={showModal} handleClose={() => setShowModal(false)} url={shareUrl} />
      <ContactModal show={showContactModal} handleClose={() => setShowContactModal(false)} />
      <AboutUsModal show={showAboutUsModal} handleClose={() => setShowAboutUsModal(false)} />
    </>
  );
});

export default CustomNavbar;