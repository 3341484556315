import React from 'react';
import { Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { IoMail, IoCall, IoLocationSharp } from "react-icons/io5";
import { FaInstagram, FaXTwitter, FaTiktok, FaLinkedin } from "react-icons/fa6";
import './ContactModal.css'; // Import the CSS file for styling
import { useTranslation } from 'react-i18next';

/**
 * ContactModal Component
 * 
 * This component renders a modal for displaying contact information, including email, phone number, location, and social media links.
 * 
 * Key Features:
 * 
 * 1. **Conditional Tooltip Rendering**:
 *    - Tooltips are displayed on hover, but only on non-mobile devices.
 * 
 * 2. **Mobile-specific Email Handling**:
 *    - On mobile devices, clicking the email link triggers a confirmation before opening the email client.
 * 
 * 3. **Responsive Design**:
 *    - The modal adjusts its behavior and appearance based on the device's screen size.
 */

const ContactModal = ({ show, handleClose }) => {

    const { t } = useTranslation(); // Initialize translation hook for multi-language support

    // Determine if the device is a mobile device based on window width
    const isMobile = window.innerWidth <= 768;

    /**
     * Handle email link click for mobile devices.
     * Shows a confirmation dialog before opening the email client.
     * @param {Event} e - The click event.
     */
    const handleEmailClick = (e) => {
        if (isMobile) {
            e.preventDefault();
            const confirmAction = window.confirm(t('confirm_email'));
            if (confirmAction) {
                window.location.href = 'mailto:info@dvcapartments.com';
            }
        }
    };

    /**
     * Renders a tooltip with the given message if not on a mobile device.
     * @param {JSX.Element|string} children - The content to display inside the tooltip.
     * @param {string} tooltipText - The text to display in the tooltip.
     * @returns {JSX.Element|string} - The content wrapped in a tooltip if applicable, or just the content.
     */
    const renderTooltip = (children, tooltipText) => {
        if (!isMobile) {
            return (
                <OverlayTrigger
                    placement="right"
                    overlay={<Tooltip>{tooltipText}</Tooltip>}
                >
                    {children}
                </OverlayTrigger>
            );
        }
        return children; // Return plain content on mobile devices
    };

    return (
        <Modal className='contactus-modal' show={show} onHide={handleClose} centered>
            <Modal.Header closeButton>
                <Modal.Title>{t('contact_us')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="contact-info">
                    <div className="contact-item">
                        <IoMail className="contact-icon" />
                        {renderTooltip(
                            <a href="mailto:info@dvcapartments.com" onClick={handleEmailClick}>
                                info@dvcapartments.com
                            </a>,
                            t('send_email')
                        )}
                    </div>
                    <div className="contact-item">
                        <IoCall className="contact-icon" />
                        {renderTooltip(
                            <a href="tel:0173591988">0173 591988</a>,
                            t('call_number')
                        )}
                    </div>
                    <div className="contact-item">
                        <IoLocationSharp className="contact-icon" />
                        <p>Piazza Michele Ferrero, 4, 12051 Alba CN, Italy</p>
                    </div>
                    <div className='contact-social-icons'>
                        {/* Social media icons with links */}
                        <a
                            href="https://www.instagram.com/dvcapartments_/"
                            target="_blank"
                            rel="noopener noreferrer"
                            aria-label="DVC Apartments on Instagram"
                        >
                            <FaInstagram />
                        </a>
                        <a
                            href="https://twitter.com/yourTwitterHandle"
                            target="_blank"
                            rel="noopener noreferrer"
                            aria-label="DVC Apartments on Twitter"
                        >
                            <FaXTwitter />
                        </a>
                        <a
                            href="https://www.tiktok.com/@dvcapartments"
                            target="_blank"
                            rel="noopener noreferrer"
                            aria-label="DVC Apartments on TikTok"
                        >
                            <FaTiktok />
                        </a>
                        <a
                            href="https://www.linkedin.com/company/dvc-apartments/"
                            target="_blank"
                            rel="noopener noreferrer"
                            aria-label="DVC Apartments on LinkedIn"
                        >
                            <FaLinkedin />
                        </a>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default ContactModal;