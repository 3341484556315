import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import Lightbox from 'yet-another-react-lightbox';
import Counter from "yet-another-react-lightbox/plugins/counter";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import 'yet-another-react-lightbox/styles.css';
import "yet-another-react-lightbox/plugins/thumbnails.css";
import "yet-another-react-lightbox/plugins/counter.css";
import './MobileContainerPic.css';
import { useTranslation } from 'react-i18next';

/**
 * MobileContainerPic Component
 * 
 * The MobileContainerPic component provides a mobile-optimized view of apartment images. It includes a main image 
 * and two smaller images displayed in a grid layout. Users can view a larger version of the images in a lightbox modal.
 * The component also handles image loading states and applies blur transformations for performance optimization.
 * 
 * Key Features:
 * 
 * 1. **Mobile-Optimized Layout**:
 *    - Uses Bootstrap's `Row` and `Col` to create a responsive layout suitable for mobile devices.
 * 
 * 2. **Image Loading**:
 *    - Applies a blur transformation to images for faster initial loading. Actual images load once the user interacts 
 *      with them.
 * 
 * 3. **Lightbox Integration**:
 *    - Enables viewing of all images in a lightbox modal with zoom, counter, and thumbnails plugins.
 * 
 * 4. **Internationalization**:
 *    - Uses `react-i18next` to support dynamic text rendering for different languages.
 * 
 * Props:
 * - `apartment`: An object containing an array of image URLs.
 */

export default function MobileContainerPic({ apartment }) {
    // Translation hook for internationalization
    const { t } = useTranslation();
    
    // State for handling lightbox visibility and image index
    const [isOpen, setIsOpen] = useState(false);
    const [photoIndex, setPhotoIndex] = useState(0);
    
    // State to manage image loading status
    const [loaded, setLoaded] = useState([false, false, false]); 

    // Function to handle image load event
    const handleImageLoad = (index) => {
        setLoaded(prevLoaded => {
            const newLoaded = [...prevLoaded];
            newLoaded[index] = true;
            return newLoaded;
        });
    };

    // Function to add a blur transformation to the image URL for performance improvement
    const addBlurTransformation = (url) => {
        const cloudinaryUrl = 'https://res.cloudinary.com/duwp1uf1h/image/upload';
        const publicId = url.split(cloudinaryUrl + '/')[1]; // Extract publicId from the URL
        return `${cloudinaryUrl}/t_Tiny/${publicId}`; // Apply blur transformation
    };

    // Generate blurred image URLs
    const blurredImages = apartment.images.map(addBlurTransformation);

    return (
        <div style={{ margin: "60px 20px 0" }}>
            <Row className="mobile-images">
                <Col xs={12} className="mb-4">
                    <div className="image-and-button-container" onClick={() => setIsOpen(true)}>
                        <img 
                            src={loaded[0] ? apartment.images[0] : blurredImages[0]} 
                            alt="Main Pic of Apartment" 
                            onLoad={() => handleImageLoad(0)}
                            className="img-fluid rounded"
                        />
                        <div className="see-all-photos-button-mobile rounded">
                            {t('see_all_photos')}
                        </div>
                    </div>
                </Col>
            </Row>
            <Row className="mobile-images-row">
                <Col xs={6} className="mb-4">
                    <img 
                        src={loaded[1] ? apartment.images[1] : blurredImages[1]} 
                        alt="Second Pic of Apartment" 
                        onLoad={() => handleImageLoad(1)}
                        className="img-fluid rounded"
                    />
                </Col>
                <Col xs={6} className="mb-4">
                    <img 
                        src={loaded[2] ? apartment.images[2] : blurredImages[2]} 
                        alt="Third Pic of Apartment" 
                        onLoad={() => handleImageLoad(2)}
                        className="img-fluid rounded"
                    />
                </Col>
            </Row>

            {isOpen && (
                <Lightbox
                    slides={apartment.images.map(image => ({ src: image }))}
                    open={isOpen}
                    close={() => setIsOpen(false)}
                    index={photoIndex}
                    onIndexChange={setPhotoIndex}
                    plugins={[Counter, Thumbnails, Zoom]}
                    counter={{ container: { style: { top: 0 } } }}
                />
            )}
        </div>
    );
}